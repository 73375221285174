// Footer
footer {
  padding-bottom: 30px;
  @include span-columns(12);
  @include media($mobile-tab) { padding-left: 5px; padding-right: 5px; }
  p,
  #e_welcome { display: inline-block; }
  #e_welcome {
    margin-top: 10px;
  }
}
.GCDI-footer {
  @include span-columns(12);
  text-align: center;
}

#scrollUp {
  @include media($mobile) {
    text-align: center;
    padding: 10px 5px;
    font-size: 36px;
    text-decoration: none;
    @include letterpress(10);
    color: lighten($base,10%);
    -webkit-transition: margin-bottom 150ms linear;
    -moz-transition: margin-bottom 150ms linear;
    transition: margin-bottom 150ms linear;
  }
}
