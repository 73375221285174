.left_nav {
  @include span-columns(2 of 12);
  @include nobullet-nospace;
  z-index: 10;
  font-family: Arial, sans-serif;
  padding: 32px 0;
  @include media($mobile) {
    width: 100%;
    padding: 10px 0;
    margin: 0 auto;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: $black;
    &:hover { text-decoration: underline; }
    &.selected {
      color: $blue;
      font-weight: bold;
      &:hover { text-decoration: none; }
    }
  }

  ul {
    @include media($mobile) {
      margin-top: 10px;
      text-align: center;
    }
    li {
      padding-bottom: 5px;
      &:last-child {
        padding-bottom: 20px;
        border-bottom: 1px solid $grayLighter;
        margin-bottom: 20px;
      }
      @include media($mobile) {
        font-size: 14px;
        padding: 10px 0;
        &:last-child {
          margin: 0;
          padding-bottom: 10px;
        }
      }
    }
  }
  .shop-by {
    @extend %container-shadow;
    padding: 10px;
    margin-bottom: 10px;
    li:last-child {
      border: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .modal-body ul li:last-child {
    border-bottom: none;
  }
  .product-icon-popup {
    cursor: pointer;
  }
  .gift-card {
    margin: 20px 0;
  }
}
.free-items-popup {
  cursor: pointer;
}