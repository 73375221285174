.cross-sells-container {
  @include span-columns(12 of 12);
  @include omega;
  @include nobullet-nospace;
  @include media($mobile) { display: none; }
  h5 {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
  }
  li {
    @include span-columns(3);
    text-align: center;
    &:last-child {
      @include omega;
    }
    .cross-sale-price {
      font-size: 13px;
    }
  }
}
