// CSS3 Flexible Box Model and property defaults

// Custom shorthand notation for flexbox
@mixin box($orient: inline-axis, $pack: start, $align: stretch) {
  @include display-box;
  @include box-orient($orient);
  @include box-pack($pack);
  @include box-align($align);
}

@mixin display-box {
  display: -webkit-box;
  display: -moz-box;
  display: box;
}

@mixin box-orient($orient: inline-axis) {
// horizontal|vertical|inline-axis|block-axis|inherit
  @include prefixer(box-orient, $orient, webkit moz spec);
}

@mixin box-pack($pack: start) {
// start|end|center|justify
  @include prefixer(box-pack, $pack, webkit moz spec);
}

@mixin box-align($align: stretch) {
// start|end|center|baseline|stretch
  @include prefixer(box-align, $align, webkit moz spec);
}

@mixin box-direction($direction: normal) {
// normal|reverse|inherit
  @include prefixer(box-direction, $direction, webkit moz spec);
}

@mixin box-lines($lines: single) {
// single|multiple
  @include prefixer(box-lines, $lines, webkit moz spec);
}

@mixin box-ordinal-group($int: 1) {
  @include prefixer(box-ordinal-group, $int, webkit moz spec);
}

@mixin box-flex($value: 0.0) {
  @include prefixer(box-flex, $value, webkit moz spec);
}

@mixin box-flex-group($int: 1) {
  @include prefixer(box-flex-group, $int, webkit moz spec);
}
