.checkout-step {
  @include nobullet-nospace;
  list-style-type: none;

  a {
    color: $white;

    &:hover,
    &:focus,
    &:active {
      color: darken($white, 10%);
    }
  }

  @include media($mobile-tab) {
    margin-top: 20px;
  }

  ul {
    margin: 0 50px 10px 50px;
    text-align: center;

    @include media($mobile) {
      margin: 0;
    }
  }

  li {
    display: inline-block;
    margin: 0 20px 20px 20px;
    text-align: center;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @include media($mobile) {
      margin-right: 10px;
      margin-left: 10px;
    }

    .block {
      display: block;
      margin: 0 auto;
    }

    .circle {
      font-size: 18px;
      background-color: $grayLighter;
      color: lighten($grayDarker, 5%);
      border-radius: 50%;
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-weight: 700;
      display: block;
      margin: 0 auto 10px;

      @include media($mobile) {
        font-size: 14px;
        line-height: 30px;
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
      }
    }

    .active {
      border-bottom: 1px solid $grayDarker;
    }

    .past,
    .b {
      background-color: lighten($blue, 15%);
      color: $blue;
    }

    .a {
      background-color: $blue;
      color: $white;
      box-shadow: 0 0 0 5px rgba(0,0,0,0.1);
    }

    span {
      font-size: 14px;
      font-weight: 700;
      color: $blue;

      @include media($mobile) {
        font-size: 11px;
      }
    }
  }
}

.checkout {
  .confirm-order {
    text-align: center;

    h3 {
      margin: 0;
    }

    .order-num {
      font-size: 18px;
      color: #e31929;
    }
  }

  .order-num {
    font-size: 18px;
    color: $blue;
  }

  .progress-btns {
    @include span-columns(12);
    @include omega;
    margin: 20px 0;

    .btn-continue {
      float: right;
    }

    .btn-back {
      float: left;
    }
  }

  .cc-contain {
    @include span-columns(12);
    @include omega;

    .block {
      @include span-columns(3 of 12);

      &:last-child {
        @include omega;
      }

      @include media($mobile) {
        @include span-columns(12);
      }

      input[type=radio] {
        display: none;
      }

      .check-with-label + .label-for-check {
        padding: 10px;
        border: 1px solid $grayLighter;
        cursor: pointer;
      }

      .check-with-label:checked + .label-for-check {
        background-color: $grayLighter;
        padding: 10px;
        border: 1px solid $grayDarker;
        cursor: pointer;
      }

      ul {
        list-style-type: none;
      }

      li {
        padding-bottom: 10px;
        font-size: 14px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  label {
    font-weight: bold;
    padding: 14px 0 0 0;
    letter-spacing: .5px;
    text-transform: uppercase;
    cursor: default;
    font-size: .875em;
  }

  label.radio {
    text-transform: none;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0;
    margin-left: 20px;
    padding: 0;
    cursor: pointer;
  }

  label.checkbox {
    @extend .radio;
  }

  .billing-address {
    select {
      width: 270px;

      @include media($mobile) {
        width: 220px;
      }
    }
  }

  .gift-msg-check {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: $wellBackground;
    border: 1px solid darken($wellBackground, 7%);
    border-radius: $baseBorderRadius;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    display: inline-block;
  }

  .payment-method {
    margin-top: 20px;

    label {
      display: block;
    }

    ul {
      list-style-type: none;
    }

    li {
      display: inline-block;
      padding-left: 10px;

      &:first-child {
        padding-left: 0;
      }
    }

    padding-bottom: 0;
  }

  .inline-btn {
    margin: 0;
  }

  .blue_link {
    color: $blue;
  }

  .recap {
    ul {
      list-style-type: none;
    }

    .btn-small {
      margin-top: 10px;
    }

    .billing-container {
      @include span-columns(9);
      border: 1px solid $grayLighter;
      padding: 0 30px 10px;

      .address, .info {
        @include span-columns(4 of 9);
      }

      .info {
        @include omega;
      }
    }

    .shipping-container {
      @include span-columns(12);
      @include omega;
      padding: 0 30px 10px;
      margin-top: 10px;

      ul {
        @include span-columns(4 of 12);

        &:last-child {
          @include omega;
        }
      }
    }

    .basket-total {
      @include span-columns(3);
      @include omega;
      overflow: hidden;

      @include media($mobile) {
        @include span-columns(12);
        @include omega;
      }

      ul {
        margin: 0;
        list-style-type: none;
        background-color: lighten($body,5%);
        padding: 10px;
        @extend %container-shadow;
      }

      li.checkout-info {
        .btn {
          display: block;
          margin-bottom: 30px;
        }

        p {
          margin: 5px 0;

          span {
            float: right;
          }
        }
      }
    }
  }
}

.addmore {
  width: 120px;
  text-align: center;
}

.row-off, .hidden {
  display: none;
}

.row-on, .visible {
  display: block;
  margin: 5px 0px;
}
