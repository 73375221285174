.page_wrapper {
  @include span-columns(12);
  overflow: hidden;
  @include media($mobile-tab) { padding: 0 20px; }
  .right_content {
    @include span-columns(8 of 12);
    @include omega;
    @include pad(30px 0);
    @include media($mobile) {
      @include span-columns(12 of 12);
      @include omega;
    }
    ol { margin-left: 35px; }
    .site-map {
      ul { margin-left: 35px; }
    }
  }
}
