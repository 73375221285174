.pagination-top, .pagination-bottom {
  @include span-columns(4);
  @include omega;
  text-align: right;
  margin-top: 10px;
  @include media($mobile) {
    font-size: 21px;
    @include span-columns(12);
    text-align: center;
    .number-range {
      display: block;
      margin-bottom: 5px;
    }
  }
}
.pagination-bottom {
  @include span-columns(12);
  @include omega;
  margin-bottom: 20px;
}

.page {
  display: inline-block;
  margin: 0;
  padding: 0 10px;
  line-height: 1em;
  text-decoration: none;
  color: $grayDark;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,.05);
  @include border-radius(2px);
  &:first-child { margin-right: -4px; }
  &:hover {
    border: 1px solid rgba(0,0,0,.08);
    color: $white;
    background-color: $blue;
  }
  &.disabled {
    color: $grayLighter;
    cursor: default;
    border: 0;
    background-color: $white;
  }
  @include media($mobile) {
    padding: 0 60px;
    background-color: darken($grayLighter, 5%);
  }
}