
// Core variables and mixins
@import "variables"; // Modify this for custom colors, font-sizes, etc
@import "mixins";

// CSS Reset
@import "reset";

// Grid system and page structure
@import "scaffolding";


// Base CSS
@import "type";
@import "forms";
@import "tables";
// Components: common
@import "wells";
@import "component-animations";
@import "close";

// Components: Buttons & Alerts
@import "buttons";
@import "button-groups";
@import "alerts"; // Note: alerts share common CSS with buttons and thus have styles in buttons

// Components: Nav
@import "navs";
@import "navbar";
@import "breadcrumbs";
@import "pagination";

// Components: Popovers
@import "modals";

// Utility classes
@import "utilities"; // Has to be last to override when necessary

//test push!