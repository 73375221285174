@mixin span-columns($span: $columns of $container-columns, $display: block) {

  $columns: nth($span, 1);
  $container-columns: container-span($span);
  $display-table: false;

  @if $container-columns != $grid-columns {
    $parent-columns: $container-columns;
  }

  @else {
    $parent-columns: $grid-columns;
  }

  @if $container-display-table == true {
    $display-table: true;
  }

  @else if $display == table {
    $display-table: true;
  }

  @else {
    $display-table: false;
  }

  @if $display-table  {
    display: table-cell;
    padding-right: flex-gutter($container-columns);
    width: flex-grid($columns, $container-columns) + flex-gutter($container-columns);

    &:last-child {
      width: flex-grid($columns, $container-columns);
      padding-right: 0;
    }
  }

  @else if $display == inline-block {
    @include inline-block;
    margin-right: flex-gutter($container-columns);
    width: flex-grid($columns, $container-columns);

    &:last-child {
      margin-right: 0;
    }
  }

  @else {
    display: block;
    float: left;
    margin-right: flex-gutter($container-columns);
    width: flex-grid($columns, $container-columns);

    &:last-child {
      margin-right: 0;
    }
  }
}
