// Return vendor-prefixed property names if appropriate
// Example: transition-property-names((transform, color, background), moz) -> -moz-transform, color, background
//************************************************************************//
@function transition-property-names($props, $vendor: false) {
	$new-props: ();
	
	@each $prop in $props {
		$new-props: append($new-props, transition-property-name($prop, $vendor), comma);
	}

	@return $new-props;
}

@function transition-property-name($prop, $vendor: false) {
	// put other properties that need to be prefixed here aswell
	@if $vendor and $prop == transform {
		@return unquote('-'+$vendor+'-'+$prop);
	}
	@else {
		@return $prop;
	}
}