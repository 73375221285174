.hero {
  display: block;
  position: relative;
  z-index: 0;
  .error {
    width: 400px;
    padding: 20px;
    @extend %container-shadow;
    text-align: center;
    margin: 20px 0;
    margin-left: auto;
    margin-right: auto;
  }
}
.home-page-mobile {
  background: url(../images/Glance-Bike-Photo-mobile.jpg) no-repeat;
  display: block;
  width: 320px;
  height: 295px;
  margin: 0 auto;
}

#home_slide {
  margin: 25px 0;
  position: relative;
  display: inline-block;
  height: 430px;
}
#home_slide div {
  z-index: 1;
  position: absolute;
  opacity: 0.0;
  height: 430px;
  width: 1170px;
}
#home_slide div.home_slide_active {
  z-index: 3;
  opacity: 1.0;
  height: 430px;
  width: 1170px;
}
.bottom-home-imgs {
  @include span-columns(12);
  @include omega;
  .mobile-region {
    h4 {
      margin-top: 10px;
      text-align: center;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
  }
  li {
    display: inline-block;
    @include span-columns(4 of 12);
    &:last-child { @include omega; }
  }
  @include media($mobile) {
    ul {
      display: block;
      margin: 0 auto;
    }
    li {
      @include span-columns(12);
      margin-bottom: 5px;
      text-align: center;
      &:last-child {
        @include omega;
        margin-bottom: 0;
      }
    }
  }
}


