/* The main calendar widget.  DIV containing a table. */

div.calendar { position: relative; }

.calendar, .calendar table {
  border: 1px solid #556;
  font-size: 11px;
  color: #000;
  cursor: default;
  background: #eef;
  font-family: tahoma,verdana,sans-serif;
}

/* Header part -- contains navigation buttons and day names. */

.calendar .button { /* "<<", "<", ">", ">>" buttons have this class */
  text-align: center;    /* They are the navigation buttons */
  padding: 2px;          /* Make the buttons seem like they're pressing */
}

.calendar .nav {
  background: #778 url(menuarrow.gif) no-repeat 100% 100%;
}

.calendar thead .title { /* This holds the current "month, year" */
  font-weight: bold;      /* Pressing it will take you to the current date */
  text-align: center;
  background: #fff;
  color: #000;
  padding: 2px;
}

.calendar thead .headrow { /* Row <TR> containing navigation buttons */
  background: #778;
  color: #fff;
}

.calendar thead .daynames { /* Row <TR> containing the day names */
  background: #bdf;
}

.calendar thead .name { /* Cells <TD> containing the day names */
  border-bottom: 1px solid #556;
  padding: 2px;
  text-align: center;
  color: #000;
}

.calendar thead .weekend { /* How a weekend day name shows in header */
  color: #a66;
}

.calendar thead .hilite { /* How do the buttons in header appear when hover */
  background-color: #aaf;
  color: #000;
  border: 1px solid #04f;
  padding: 1px;
}

.calendar thead .active { /* Active (pressed) buttons in header */
  background-color: #77c;
  padding: 2px 0px 0px 2px;
}

/* The body part -- contains all the days in month. */

.calendar tbody .day { /* Cells <TD> containing month days dates */
  width: 2em;
  color: #456;
  text-align: right;
  padding: 2px 4px 2px 2px;
}
.calendar tbody .day.othermonth {
  font-size: 80%;
  color: #bbb;
}
.calendar tbody .day.othermonth.oweekend {
  color: #fbb;
}

.calendar table .wn {
  padding: 2px 3px 2px 2px;
  border-right: 1px solid #000;
  background: #bdf;
}

.calendar tbody .rowhilite td {
  background: #def;
}

.calendar tbody .rowhilite td.wn {
  background: #eef;
}

.calendar tbody td.hilite { /* Hovered cells <TD> */
  background: #def;
  padding: 1px 3px 1px 1px;
  border: 1px solid #bbb;
}

.calendar tbody td.active { /* Active (pressed) cells <TD> */
  background: #cde;
  padding: 2px 2px 0px 2px;
}

.calendar tbody td.selected { /* Cell showing today date */
  font-weight: bold;
  border: 1px solid #000;
  padding: 1px 3px 1px 1px;
  background: #fff;
  color: #000;
}

.calendar tbody td.weekend { /* Cells showing weekend days */
  color: #a66;
}

.calendar tbody td.today { /* Cell showing selected date */
  font-weight: bold;
  color: #00f;
}

.calendar tbody .disabled { color: #999; }

.calendar tbody .emptycell { /* Empty cells (the best is to hide them) */
  visibility: hidden;
}

.calendar tbody .emptyrow { /* Empty row (some months need less than 6 rows) */
  display: none;
}

/* The footer part -- status bar and "Close" button */

.calendar tfoot .footrow { /* The <TR> in footer (only one right now) */
  text-align: center;
  background: #556;
  color: #fff;
}

.calendar tfoot .ttip { /* Tooltip (status bar) cell <TD> */
  background: #fff;
  color: #445;
  border-top: 1px solid #556;
  padding: 1px;
}

.calendar tfoot .hilite { /* Hover style for buttons in footer */
  background: #aaf;
  border: 1px solid #04f;
  color: #000;
  padding: 1px;
}

.calendar tfoot .active { /* Active (pressed) style for buttons in footer */
  background: #77c;
  padding: 2px 0px 0px 2px;
}

/* Combo boxes (menus that display months/years for direct selection) */

.calendar .combo {
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  width: 4em;
  cursor: default;
  border: 1px solid #655;
  background: #def;
  color: #000;
  font-size: 90%;
  z-index: 100;
}

.calendar .combo .label,
.calendar .combo .label-IEfix {
  text-align: center;
  padding: 1px;
}

.calendar .combo .label-IEfix {
  width: 4em;
}

.calendar .combo .hilite {
  background: #acf;
}

.calendar .combo .active {
  border-top: 1px solid #46a;
  border-bottom: 1px solid #46a;
  background: #eef;
  font-weight: bold;
}

.calendar td.time {
  border-top: 1px solid #000;
  padding: 1px 0px;
  text-align: center;
  background-color: #f4f0e8;
}

.calendar td.time .hour,
.calendar td.time .minute,
.calendar td.time .ampm {
  padding: 0px 3px 0px 4px;
  border: 1px solid #889;
  font-weight: bold;
  background-color: #fff;
}

.calendar td.time .ampm {
  text-align: center;
}

.calendar td.time .colon {
  padding: 0px 2px 0px 3px;
  font-weight: bold;
}

.calendar td.time span.hilite {
  border-color: #000;
  background-color: #667;
  color: #fff;
}

.calendar td.time span.active {
  border-color: #f00;
  background-color: #000;
  color: #0f0;
}
