//
// Modals
// --------------------------------------------------


.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0p;
  // Fade for backdrop
  &.fade { opacity: 0; }
}

.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 80;
}

// Base modal
.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: $zindexModal;
  width: 560px;
  margin-left: -280px;
  background-color: $white;
  border: 1px solid #999;
  border: 1px solid rgba(0,0,0,.3);
  *border: 1px solid #999; /* IE6-7 */
  border-radius: 2px;
  box-shadow: 0 3px 7px rgba(0,0,0,0.3);
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: none;

  &.fade {
    transition: opacity .3s linear;
    top: 25%;
  }
  &.fade.in { top: 25%; }
  form {
    margin: 0;
  }
  @include media($tablet) {
    top: 2%;
  }
  @include media($bitty) {
    top: 3%;
    right: 3%;
    left: 3%;
    width: auto;
    margin: 0;
  }
}
.modal-header {
  padding: 10px 50px 10px 15px;
  h5 { margin: 0; display: inline; }
  // Close icon
  .close:before {
    content: '\F00D';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
    font-size: 18px;
    padding-right: 0.8em;
    position: absolute;
    top: 10px;
    right: 1px;
  }
  // Heading
  h3 {
    margin: 0;
    line-height: 30px;
  }
}

// Body (where all modal content resides)
.modal-body {
  position: relative;
  overflow-y: auto;
  max-height: 800px;
  padding: 0 25px 25px 10px;
  -webkit-overflow-scrolling: touch;
  h5 { display: inline; }

  .quickshop_wrapper {
    @include outer-container;
    .quickshop_contents {
      @include span-columns(12);
      .product_image {
        @include span-columns(4 of 12);

      }
      .product_info {
        @include span-columns(8 of 12);
        display: block;
        .highlight-stock {
          margin-bottom: 10px !important;
        }
        label {
          font-weight: bold;
          padding: 0;
          letter-spacing: .5px;
          cursor: default;
        }
      }
    }
  }
}
// Remove bottom margin if need be
.modal-form {
  margin-bottom: 0;
}

// Footer (for actions)
.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right; // right align buttons
  background-color: $wellBackground;
  border-top: 1px solid #ddd;
  border-radius: 0 0 2px 2px;
  box-shadow: inset 0 1px 0 $white;
  @include clearfix(); // clear it in case folks use .pull-* classes on buttons
  .help-text { font-size: $fontSizeSmall; }


  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}
