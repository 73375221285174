.promos {
  @include span-columns(12);
  @include omega;
  margin-top: 10px;
  @include media($mobile) { display: none; }
}

.daily-promo {
  margin: 20px 0;
  @include media($mobile) {
    margin: 20px 0 auto;
  }
  img { float: left; }
  ul {
    list-style-type: none;
    float: left;
  }
  button {
    width: 100%;
    margin: 5px 0;
    display: block;
  }
}