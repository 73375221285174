body {
  background-color: #fff;
}
// Loading bar
.pace .pace-progress {
  background: #4b7abe;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 2px;

  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
}
#cover {
  background: url("../images/ajax-loader.gif") no-repeat scroll center center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 4000;
  display: none;
}
.content {
  @include outer-container;
}

input, textarea {
  -webkit-border-radius:0;
  @include border-radius(none);
}

.main-bg {
  @include media($tablet) {
    padding-left: 10px;
    padding-right: 10px;
  }
}


a {
  color: $base;
  &:hover {
    color: darken($base, 10%);
    text-decoration: none;
  }
}

.desktop-region {
  display: block;
  @include media($mobile) { display: none; }
}
.desktop-region-2 {
  display: block;
  @include media($mobile-tab) { display: none; }
}

.mobile-region {
  display: none;
  @include media($mobile) { display: block; }
}
.highlight-stock {
  margin: 0;
  color: $green;
  font-weight: bold;
}