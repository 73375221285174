//************************************************************************//
// Background-image property for adding multiple background images with
// gradients, or for stringing multiple gradients together.
//************************************************************************//

@mixin background-image($images...) {
  background-image: _add-prefix($images, webkit);
  background-image: _add-prefix($images);
}

@function _add-prefix($images, $vendor: false) {
  $images-prefixed: ();
  $gradient-positions: false;
  @for $i from 1 through length($images) {
    $type: type-of(nth($images, $i)); // Get type of variable - List or String

    // If variable is a list - Gradient
    @if $type == list {
      $gradient-type: nth(nth($images, $i), 1); // linear or radial
      $gradient-pos: null;
      $gradient-args: null;

      @if ($gradient-type == linear) or ($gradient-type == radial) {
        $gradient-pos:  nth(nth($images, $i), 2); // Get gradient position
        $gradient-args: nth(nth($images, $i), 3); // Get actual gradient (red, blue)
      }
      @else {
        $gradient-args: nth(nth($images, $i), 2); // Get actual gradient (red, blue)
      }

      $gradient-positions: _gradient-positions-parser($gradient-type, $gradient-pos);
      $gradient: _render-gradients($gradient-positions, $gradient-args, $gradient-type, $vendor);
      $images-prefixed: append($images-prefixed, $gradient, comma);
    }
    // If variable is a string - Image
    @else if $type == string {
      $images-prefixed: join($images-prefixed, nth($images, $i), comma);
    }
  }
  @return $images-prefixed;
}

//Examples:
  //@include background-image(linear-gradient(top, orange, red));
  //@include background-image(radial-gradient(50% 50%, cover circle, orange, red));
  //@include background-image(url("/images/a.png"), linear-gradient(orange, red));
  //@include background-image(url("image.png"), linear-gradient(orange, red), url("image.png"));
  //@include background-image(linear-gradient(hsla(0, 100%, 100%, 0.25) 0%, hsla(0, 100%, 100%, 0.08) 50%, transparent 50%), linear-gradient(orange, red));
