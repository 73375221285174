.right_content {
  @include span-columns(10 of 12);
  *width: 80%;
  padding: 20px 0;
  @include omega;
  @include media($mobile) { @include span-columns(12); }
}
  .category-name {
    font-size: 24px;
    color: #3366cc;
    margin: 0 0 15px 0;
    line-height: 1.1;
    @include media($mobile) { font-size: 12px; }
  }
.category-product-list {
    @include span-columns(12);
    @include omega;
    text-align: center;


    .category-page-title {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: $grayDarker;
    }
    ul {
      width: 100%;
      display: inline-block;
      @extend %container-shadow;
      border: 15px solid $grayLighter;
      margin: 0;
      border-radius: 15px;
      margin-bottom: 30px;
      border-top: 0;
      .inner-category {
        background-color: white;
        display: inline-block;
        width: 100%;
        border-radius: 15px;
      }

      li {
        @include span-columns(3);
        padding: 20px;
        border-left: none;
        text-align: center;
        @include media($mobile) {
          @include span-columns(12);
          @include omega;
        }
      }
    }
    .single-product {
      margin: 20px 0;
      .prod-img { position: relative; }
    }
    .price {
      font-weight: bold;
      color: #000;
      padding: 0 10px;
      text-align: center;
    }
}