// Mixins
@mixin animation($properties) {
  -webkit-animation: $properties;
  -moz-animation: $properties;
  -o-animation: $properties;
  animation: $properties;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-sizing {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  // *behavior: url('../js/boxsizing.htc')
}

/*@mixin box-shadow($properties) {
  -webkit-box-shadow: $properties;
  -moz-box-shadow: $properties;
  box-shadow: $properties;
}*/

@mixin fontawesome {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

@mixin inline-block {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

@mixin opacity($value) {
  opacity: $value;
  filter: alpha(opacity = $value * 100);
  zoom: 1;
}

@mixin placeholder-color($color) {
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &.placeholder {
    color: $color;
  }
}

@mixin experimental($property, $value, $moz: $experimental-support-for-mozilla, $webkit: $experimental-support-for-webkit, $o: $experimental-support-for-opera, $ms: $experimental-support-for-microsoft, $khtml: $experimental-support-for-khtml, $official: true) {
  @if $webkit and $experimental-support-for-webkit {
    -webkit-#{$property}: $value; }
  @if $khtml and $experimental-support-for-khtml {
    -khtml-#{$property}: $value; }
  @if $moz and $experimental-support-for-mozilla {
    -moz-#{$property}: $value; }
  @if $ms and $experimental-support-for-microsoft {
    -ms-#{$property}: $value; }
  @if $o and $experimental-support-for-opera {
    -o-#{$property}: $value; }
  @if $official {
    #{$property}: $value; } }

@mixin background-size($size-1: $default-background-size, $size-2: false, $size-3: false, $size-4: false, $size-5: false, $size-6: false, $size-7: false, $size-8: false, $size-9: false, $size-10: false) {
  $size-1: if(type-of($size-1) == string, unquote($size-1), $size-1);
  $sizes: compact($size-1, $size-2, $size-3, $size-4, $size-5, $size-6, $size-7, $size-8, $size-9, $size-10);
  @include experimental(background-size, $sizes, -moz, -webkit, -o, not -ms, not -khtml); }

@mixin transition($properties) {
  -webkit-transition: $properties;
  -moz-transition: $properties;
  -o-transition: $properties;
  transition: $properties;
  -webkit-backface-visibility: hidden;
}

@mixin transform($properties) {
  -webkit-transform: $properties;
  -moz-transform: $properties;
  -ms-transform: $properties;
  -o-transform: $properties;
  transform: $properties;
}

@mixin vertical-gradient($from, $to) {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $from), color-stop(100%, $to));
  background: -webkit-linear-gradient(top, $from, $to);
  background: -moz-linear-gradient(top, $from, $to);
  background: -ms-linear-gradient(top, $from, $to);
  background: -o-linear-gradient(top, $from, $to);
  background: linear-gradient(to bottom, $from, $to);
}

@mixin selection($color) {
  &::selection {
    background: $color;
  }
  &::-moz-selection {
    background: $color;
  }
}

@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

@mixin dropdown-arrow($color: $base, $top: 5px, $left: 15px, $size: 9px) {
  &:before {
    content: "";
    border-style: solid;
    border-width: 0 $size $size $size;
    border-color: transparent transparent $color transparent;
    height: 0px;
    position: absolute;
    left: $left;
    top: $top;
    width: 0px;
    // Make corners smooth
    -webkit-transform: rotate(360deg);
  }
}

@mixin drop-ie-gradient {
  filter: unquote("progid:DXImageTransform.Microsoft.gradient(enabled = false)");
}

// Remove the bullets, margin and padding from unordered list items
@mixin nobullet-nospace {
  ul {
    list-style: none outside;
    margin: 0;
    padding: 0; }
}

// triangle


@mixin triangle($direction, $color, $size) {

        @if $direction == "left" {
                border-bottom: $size solid transparent;
                border-right: $size solid $color;
                border-top: $size solid transparent;
        }
        @else if $direction == "right" {
                border-bottom: $size solid transparent;
                border-left: $size solid $color;
                border-top: $size solid transparent;
        }
        @else if $direction == "up" {
                border-bottom: $size solid $color;
                border-left: $size solid transparent;
                border-right: $size solid transparent;
        }
        @else if $direction == "down" {
                border-right: $size solid transparent;
                border-left: $size solid transparent;
                border-top: $size solid $color;
        }

        height: 0;
        width: 0;
}