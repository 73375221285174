//
// Alerts
// --------------------------------------------------


// Base styles
// -------------------------

.alert {
  padding: 8px 14px 8px 14px;
  margin-top: $line-height-base;
  margin-bottom: $line-height-base;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  background-color: $warningBackground;
  border: 1px solid $warningBorder;
  @include border-radius($baseBorderRadius);
  p { margin: 0; }
}
.alert,
.alert h4 {
  // Specified for the h4 to prevent conflicts of changing $headingsColor
  color: $warningText;
}
.alert h4 {
  margin: 0;
}

// Adjust close link position
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: $line-height-base;
}


// Alternate styles
// -------------------------

.alert-success {
  background-color: $successBackground;
  border-color: $successBorder;
  color: $successText;
}
.alert-success h4 {
  color: $successText;
}
.alert-danger,
.alert-error {
  background-color: $errorBackground;
  border-color: $errorBorder;
  color: $errorText;
}
.alert-danger h4,
.alert-error h4 {
  color: $errorText;
}
.alert-info {
  background-color: $infoBackground;
  border-color: $infoBorder;
  color: $infoText;
}
.alert-info h4 {
  color: $infoText;
}
.alert-backorder {
  display: block;
  font-size: 15px;
}

// Block alerts
// -------------------------

.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}
