// Shorthand mixin. Supports multiple parentheses-deliminated values for each variable.
// Example: @include transition (all, 2.0s, ease-in-out);
//          @include transition ((opacity, width), (1.0s, 2.0s), ease-in, (0, 2s));
//          @include transition ($property:(opacity, width), $delay: (1.5s, 2.5s));

@mixin transition ($properties...) {
  @if length($properties) >= 1 {
    @include prefixer(transition, $properties, webkit moz spec);
  }

  @else {
    $properties: all 0.15s ease-out 0;
    @include prefixer(transition, $properties, webkit moz spec);
  }
}

@mixin transition-property ($properties...) {
   -webkit-transition-property: transition-property-names($properties, 'webkit');
      -moz-transition-property: transition-property-names($properties, 'moz');
           transition-property: transition-property-names($properties, false);
}

@mixin transition-duration ($times...) {
  @include prefixer(transition-duration, $times, webkit moz spec);
}

@mixin transition-timing-function ($motions...) {
// ease | linear | ease-in | ease-out | ease-in-out | cubic-bezier()
  @include prefixer(transition-timing-function, $motions, webkit moz spec);
}

@mixin transition-delay ($times...) {
  @include prefixer(transition-delay, $times, webkit moz spec);
}
