//
// Breadcrumbs
// --------------------------------------------------
.breadcrumb-search {
  margin: 0;
  list-style: none;

  > li {
    display: inline-block;
    @include ie7-inline-block();
    text-shadow: 0 1px 0 $white;

    > .divider {
      padding: 0 5px;
      color: $red;
    }
  }
  .active {
    color: $grayLight;
  }
}

.breadcrumb {
  @include span-columns(10 of 10);
  @include media($mobile) {
    position: absolute;
    top: 70px;
    right: 0;
  }
  ul {
    float: left;
    padding: 4px 0;
    margin: 0;
    list-style: none;
    @include media($mobile) {
      float: right;
    }
  }
  > li {
    display: inline-block;
    @include ie7-inline-block();
    text-shadow: 0 1px 0 $white;

    > .divider {
      padding: 0 5px;
      color: $red;
    }
  }
  .active {
    color: $grayLight;
  }
}
