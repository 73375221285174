.no_nav_page_wrapper {
  @include span-columns(12);
  @include omega;
  @include pad(30px 0);
  form {
    .controls { margin: 10px 0 10px 180px; }
  }
  label {
    font-weight: bold;
    padding: 14px 0 0 0;
    letter-spacing: .5px;
    cursor: default;
  }
  span.required {
    color: $red;
    font-weight: bold;
    font-size: 18px;
  }
  .phone_email_chat {
    @include span-columns(4 of 12);
    @include media($mobile) {
      @include span-columns(12 of 12);
      @include omega;
      text-align: center;
      h4 { margin-top: 10px; }
    }
  }
  .contact {
    label {
      font-weight: bold;
      padding: 14px 0 0 0;
      letter-spacing: .5px;
      cursor: default;
    }
    .input {
      padding-left: 10px;
      @include span-columns(4 of 8);
      input { width: 100%; }
      @include media($mobile) {
        @include span-columns(8 of 8);
        padding-right: 10px;
      }
    }
    .input_right { @extend .input;  @include omega; }
    .textarea {
      @include span-columns(8 of 8);
      padding-left: 10px;
      textarea { width: 100%; }
    }
    .btn { margin-top: 20px; }
  }
  .login {
    display: block;
    margin: 0 auto;
    button { margin: 15px 0; display: block;  }
    @include media($mobile) {
      padding: 30px 0;
    }
  }
  .checkout-login {
    @include span-columns(4 of 12);
    @include shift(2);
    @include form-label;
    @include media($mobile) {
      @include span-columns(12);
      @include omega;
      @include shift(0);
      padding: 30px 0;
    }
    padding-right: 30px;
    .thisLogin { width: 100%; }
    button { margin: 15px 0; display: block;  }
    h4 { text-align: center; }
    font-size: 12px;

    hr { margin: 20px 0 0 0; }
    p {
      line-height: 30px;
      text-align: center;
      a { color: $blue; }
    }
  }
  .guest {
    @include span-columns(4 of 12);
    @include omega;
    padding-left: 30px;
    border-left: 1px solid $grayLighter;
    min-height: 300px;
    text-align: center;
    @include media($mobile) {
      @include span-columns(12);
      @include omega;
      border-left: 0;
      border-top: 1px solid $grayLighter;
      padding: 30px 0;
      min-height: 200px;
    }
    p { line-height: 30px; }
  }
  .theLeft, .theRight {
    @include span-columns(6);
    @include media($mobile) { @include span-columns(12); }
  }
  .theRight {
    @include omega;
  }
  @include media($mobile-tab) {
    padding: 0 20px;
  }
  @include media($mobile) {
    .GC {
      background-color: transparent;
      border: none;
    }
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
      text-align: center;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      position: relative;
    }
    .form-btns {
      padding: 0;
      .btn {
        display: block;
        float: none;
        margin: 20px 0;
        font-size: 16px;
        padding: 10px;
        width: 100%;
      }
    }
  }
}
