.product_details {
  @include span-columns(12);
  @include omega;
  @include pad(20px 0);
  font-size: 12px;
  @include media($tablet) {  padding-left: 10px; padding-right: 10px; }
  .price, #price {
    margin: 10px 0;
    font-weight: 700;
    font-size: 21px;
    padding-bottom: 10px;
    a { color: #000; }
  }
  .saleprice {
    text-decoration: line-through;
    color: $grayDark;
    display: block;
    color: red;
  }
  .main_product_image {
    @include span-columns(6 of 12);
    margin: 0 15px 0 0;
    position: relative;
    z-index: 0;
    @include media($mobile) {
      @include span-columns(12 of 12);
      @include omega;
      text-align: center;
      padding: 0 60px;
    }
    .image-over-icon {
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }

  }
  .standout {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 5px 10px;
    font-family: Arial, sans-serif;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    line-height: 1.5;
    border-color: #4b7abe;
    background-color: #4b7abe;
    background: -webkit-linear-gradient(top, #4387fd, #4683ea);
  }
  #multiple_product_images {
    @include span-columns(1 of 12);
    @include nobullet-nospace;
    @include media($mobile) {
      @include span-columns(12 of 12);
      @include omega;
      text-align: center;
    }

    #gallery {
      margin-top: 15px;
      display: block;
      li {
        @include media($mobile) {
          display: inline-block;
        }
        margin-bottom: 10px;
        .ie8 img {
          height: auto;
          width: auto;
        }
      }
    }

  }
  .product_info {
    @include span-columns(5 of 12);
    padding-left: 20px;
    *width: 40%;
    @include omega;
    @include media($mobile) {
      @include span-columns(12 of 12);
      @include omega;
      #description { min-height: 200px; }
      #sizeChart { min-height: 300px; }
    }
    @include media($bitty) {
      #sizeChart { min-height: 400px; }
    }

    #item-title {
      margin: 10px 0 0 0;
      line-height: 32px;
    }
    .nav {
      margin: 20px 0 0 0;
    }
    hr { background: darken($body, 5%); }


  }
  .product_shop {
    @include media($mobile) {
      z-index: 999;
      @include box-shadow(0 -1px 3px 0 rgba(0, 0, 0, 0.25));
      border-top: 1px solid $grayLighter;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 10px;
      background-color: lighten($body,5%);
      box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.15);
    }
    label { font-weight: 700; }
    .btn-cart {
      display: block;
      font-size: 16px;
      padding: 10px 20px;
      width: 100%;
      color: #fff;
      border-color: #b0281a;
      border-bottom-color: #af301f;
      background-color: #c53727;
      background-image: -webkit-gradient(linear,left top,left bottom,from(#dd4b39),to(#c53727));
      background-image: -webkit-linear-gradient(top,#dd4b39,#c53727);
      background-image: -moz-linear-gradient(top,#dd4b39,#c53727);
      background-image: -ms-linear-gradient(top,#dd4b39 0%,#c53727 100%);
      background-image: linear-gradient(to bottom,#dd4b39,#c53727);
    }

    .btn-wishlist {
      margin: 10px 0 0;
      padding: 0;
      border-bottom: 1px dotted $blue;
      @include media($mobile) { display: block; }
      &:hover {
        text-decoration: none;
      }
    }
    .btn-block {
      margin: 0;
    }
    hr { border-bottom: 1px solid darken($grayLighter, 10%);}
    .price-shop {
      font-weight: 700;
      font-size: 21px;
      padding-bottom: 10px;
      a { color: #000; }
    }

    .detail-style {
      .Size-style-dropdown,
      .Color-style-dropdown {
        display: inline-block;
        margin: 0 10px 20px 10px;
        select, .selectricWrapper { width:auto; }
      }

      .stock-container {
        display: inline-block;
        margin: 0 10px 20px 10px;
        @include media($mobile) {
          display: none !important;
        }
        label {
          padding-bottom: 5px;
        }
        .inventory {
          color: $green;
          font-weight: bold;
        }
      }
      .qty-contain {
        display: inline-block;
        margin: 0 0 20px 10px;
      }
    }
  }
}

.virtualholder {
  label {
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 8px 0 0 0;
    letter-spacing: .5px;
    cursor: default;
  }
  span.required {
    color: $red;
    font-weight: bold;
    font-size: 18px;
  }
  .items {
    text-align: center;
    .itemholder {
      @include span-columns(4);
      @include omega(3n);
    }
  }
  .customHolder {
    @include span-columns(12);
    margin-top: 10px;
    .designContainer {
      margin-top: 10px;
      @include span-columns(4);
    }
    .checkoutContainers {
      padding-left: 20px;
      margin-top: 10px;
      @include span-columns(4);
      @include omega(2n);
    }
    .product_shop {
      margin-top: 20px;
      margin-left: 25px;
      @include span-columns(3);
      @include omega;
      padding: 10px;
      @include border-radius(4px);
      text-align: center;

      .reset-btn {
        margin-top: -10px;
        margin-bottom: 20px;
      }
      @include media($mobile) {
        @include span-columns(12 of 12);
        @include omega;
      }
    }
  }
}
