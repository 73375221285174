.category_products {
	font-size: 12px;
	.right_content {
		@include span-columns(10 of 12);
		*width: 80%;
		padding: 20px 0;
		@include omega;
		@include media($mobile) {
			@include span-columns(12);
			padding: 0;
		}
		// Make h5 on search results allow the result to float to the right

	}
	.full_width_content {
		@include span-columns(12);
		@include omega;
		padding: 20px 0;
		ul {
			margin-left: 0;
		}
		.search_title {
			display: block;
			h4 { display: inline; }
		}
		.sortby {
			@include span-columns(8);
			margin-top: 10px;
			@include media($mobile) {
				float: right;
			}
			select {
				display: inline-block;
				width: auto;
				margin: 0;
				line-height: 12px;
				.label { font-weight: normal; }
			}
			form { display: inline-block; }
		}
		.text {
			display: inline;
			padding-right: 10px;
			@include media($mobile) {
				font-size: 14px;
				display: block;
				padding-right: 0;
			}
		}
	}

	.product_list {
		@include media($mobile) {
			margin: 0 auto;
			display: block;
		}
		ul {
			@include span-columns(10 of 10);
			@include omega;
			@include pad(30px 0 0 0);
			@include media($itty) {
				@include span-columns(12);
				@include omega;
			}
			@include media($bitty) { width: 100%; }
			li {
				list-style-type: none;
				margin-left: 0;
				display: inline;
				float: left;
				width: 260px;
				height: 270px;
				position: relative;
				margin: 0 30px 60px 0;
				text-align: center;
				.product-image {
					width: 200px;
				}
				&:nth-child(4n+4) {
					margin-right: 0;
				}
				@include media($itty) {
					margin: 10px;
					display: block;
					&:nth-child(3n+3) {
						margin-right: 0;
					}
				}
				@include media($bitty) {
					margin: 5px 0 auto 5px;
					float: none;
				}

				.quick_shop {
					display: none;
					position: absolute;
					top: 40%;
					left: 32%;
					font-weight: 700;
					text-transform: uppercase;
					color: $white;
					line-height: $fontSizeMini * 1.25;
					padding: 10px;
					cursor: pointer;
					background-color: $blue;
					opacity: .7;
					border-radius: 2px;
					transition: opacity 0.5s ease-in-out;
					&:hover, &:focus {
						opacity: 1;
					}

					@include media($tablet) {
						display: none;
					}
				}
				.ie7 &.quick_shop,
				.ie8 &.quick_shop { display: none; }
				.tags {
					position: absolute;
					top: 10px;
					right: 5px;
					@include media($bitty) {
						right: 80px;
					}
					.image-over-icon {
						display: block;
						margin-bottom: 5px;
					}
				}
				.centered {
					text-align: center;
					.saleprice {
						text-decoration: line-through;
						color: $grayDark;
						color: red;
					}
					a {
						display: block;
						text-decoration: none;
						white-space: normal;
						padding-top: 5px;

						&:hover, &:focus {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
.ie7 {
	@include span-columns(9 of 12);
	@include omega;
}

