

// Define breakpoints
$desktop: new-breakpoint(min-width 1025px);
$tablet: new-breakpoint(min-width 768px max-width 1024px);
$itty: new-breakpoint(min-width 381px max-width 767px);
$bitty: new-breakpoint(min-width 0px max-width 380px);

// Both mobile sizes
$mobile: new-breakpoint(min-width 0px max-width 767px);

$mobile-tab: new-breakpoint(min-width 0px max-width 1024px);