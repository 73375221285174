div {
  &.jGrowl {
    z-index: 9999;
    color: darken(#fff,5%);
    font-size: 12px;
  }
  &.ie6 {
    position: absolute;
    &.top-right {
      right: auto;
      bottom: auto;
      left: expression(( 0 - jGrowl.offsetWidth + ( document.documentElement.clientWidth ? document.documentElement.clientWidth : document.body.clientWidth ) + ( ignoreMe2 = document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft ) ) + 'px' );
      top: expression(( 0 + ( ignoreMe = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop ) ) + 'px' );
    }
    &.top-left {
      left: expression(( 0 + ( ignoreMe2 = document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft ) ) + 'px' );
      top: expression(( 0 + ( ignoreMe = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop ) ) + 'px' );
    }
    &.bottom-right {
      left: expression(( 0 - jGrowl.offsetWidth + ( document.documentElement.clientWidth ? document.documentElement.clientWidth : document.body.clientWidth ) + ( ignoreMe2 = document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft ) ) + 'px' );
      top: expression(( 0 - jGrowl.offsetHeight + ( document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight ) + ( ignoreMe = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop ) ) + 'px' );
    }
    &.bottom-left {
      left: expression(( 0 + ( ignoreMe2 = document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft ) ) + 'px' );
      top: expression(( 0 - jGrowl.offsetHeight + ( document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight ) + ( ignoreMe = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop ) ) + 'px' );
    }
    &.center {
      left: expression(( 0 + ( ignoreMe2 = document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft ) ) + 'px' );
      top: expression(( 0 + ( ignoreMe = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop ) ) + 'px' );
      width: 100%;
    }
  }
  &.jGrowl {
    position: absolute;
  }
}

/** Special IE6 Style Positioning **/

/** Normal Style Positions **/

body > div.jGrowl {
  position: fixed;
}

div {
  &.jGrowl {
    &.top-left {
      left: 0px;
      top: 0px;
    }
    &.top-right {
      right: 0px;
      top: 0px;
    }
    &.bottom-left {
      left: 0px;
      bottom: 0px;
    }
    &.bottom-right {
      right: 0px;
      bottom: 0px;
    }
    &.center {
      top: 0px;
      width: 50%;
      left: 25%;
    }
    &.top-center {
      top: 20%;
      width: 30%;
      left: 35%;
      @include media($mobile) {
        width: 75%;
        left: 15%;
      }
    }
  }
  &.center div {
    &.jGrowl-notification, &.jGrowl-closer {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.jGrowl div {
    &.jGrowl-notification, &.jGrowl-closer {
      background-color: rgba(83, 83, 83, .95);
      zoom: 1;
      padding: 10px;
      font-size: 14px;
      text-align: center;
      display: none;
      .btn {
        margin: 10px;
      }
    }
    &.jGrowl-notification {
      min-height: 40px;
      margin: 10px;
    }
    &.jGrowl-closer {
      margin: 10px;
    }
    &.jGrowl-notification div {
      &.jGrowl-header {
        font-size: 1.2em;
      }
      &.jGrowl-close {
        z-index: 99;
        float: right;
        font-weight: bold;
        font-size: 1em;
        cursor: pointer;
        color: transparent;
      }
    }
    &.jGrowl-closer {
      padding-top: 4px;
      padding-bottom: 4px;
      cursor: pointer;
      font-size: .9em;
      font-weight: bold;
      text-align: center;
    }
    hr {
      display: block; height: 1px;
      border: 0; border-top: 1px solid #ccc;
      margin: 1em 0; padding: 0;
    }
  }
}

/** Cross Browser Styling **/
.btn-viewcart {
  margin-top: 20px;
  font-weight: bold;
}

.btn-checkout {
  @extend .btn-viewcart;
  float: right;
}
/** Hide jGrowl when printing **/
@media print {
  div.jGrowl {
    display: none;
  }
}