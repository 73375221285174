//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

// Core
.btn {
  font-size: 11px;
  font-weight: bold;
  display: inline-block;
  @include ie7-inline-block();
  padding: $paddingSmall;
  margin-bottom: 0; // For input.btn
  line-height: $line-height-base;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid $btnBorder;
  border-color: rgba(0,0,0,0.1);
  color: $grayDark;
  background: #f1f1f1;
  linear-gradient: top, #f5f5f5, #f1f1f1;
  border-color: #c6c6c6;
  *border: 0; // Remove the border to prevent IE7's black border on input:focus

  border-radius: $borderRadiusSmall;
  @include ie7-restore-left-whitespace(); // Give IE7 some love
  // Hover/focus state
  &:hover,
  &:focus {
    color: $grayDarker;
    text-decoration: none;
    border-color: #c6c6c6;
    background: #f8f8f8;
    linear-gradient: top, #f8f8f8, #f1f1f1;
    box-shadow: 0 1px 0 rgba(0,0,0,.15);

    // transition is only when going to hover/focus, otherwise the background
    // behind the gradient (there for IE<=9 fallback) gets mismatched
    transition: background-position .1s linear;
  }

  // Focus state for keyboard and accessibility
  &:focus {
    @include tab-focus();
  }

  // Active state
  &.active,
  &:active {
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 2px rgba(0,0,0,.1);
  }

  // Disabled state
  &.disabled,
  &[disabled] {
    cursor: default;
    background-image: none;
    opacity: 65;
    box-shadow: none;
  }

}



// Button Sizes
// --------------------------------------------------

// Large
.btn-large {
  padding: $paddingLarge;
  font-size: $fontSizeLarge;
  border-radius: $borderRadiusLarge;
}
.btn-large [class^="icon-"],
.btn-large [class*=" icon-"] {
  margin-top: 4px;
}

// Small
.btn-small {
  padding: $paddingSmall;
  font-size: $fontSizeSmall;
  border-radius: $borderRadiusSmall;
}
.btn-small [class^="icon-"],
.btn-small [class*=" icon-"] {
  margin-top: 0;
}
.btn-mini [class^="icon-"],
.btn-mini [class*=" icon-"] {
  margin-top: -1px;
}

// Mini
.btn-mini {
  padding: $paddingMini;
  font-size: $fontSizeMini;
  border-radius: $borderRadiusSmall;
}


// Block button
// -------------------------

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  @include box-sizing(border-box);
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}



// Alternate buttons
// --------------------------------------------------

// Provide *some* extra contrast for those who can get it
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active,
.btn-cart.active {
  color: rgba(255,255,255,.75);
}

// Set the backgrounds
// -------------------------
.btn-primary {
  @include buttonBackground($btnPrimaryBackground, $btnPrimaryBackgroundHighlight);
}
// Warning appears are orange
.btn-warning {
  @include buttonBackground($btnWarningBackground, $btnWarningBackgroundHighlight);
}
// Danger and error appear as red
.btn-danger {
  @include buttonBackground($btnDangerBackground, $btnDangerBackgroundHighlight);
}
// Success appears as green
.btn-success {
  @include buttonBackground($btnSuccessBackground, $btnSuccessBackgroundHighlight);
}
// Info appears as a neutral blue
.btn-info {
  @include buttonBackground($btnInfoBackground, $btnInfoBackgroundHighlight);
}
// Inverse appears as dark gray
.btn-inverse {
  @include buttonBackground($btnInverseBackground, $btnInverseBackgroundHighlight);
}


// Cross-browser Jank
// --------------------------------------------------

button.btn,
input[type="submit"].btn {

  // Firefox 3.6 only I believe
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  // IE7 has some default padding on button controls
  *padding-top: 3px;
  *padding-bottom: 3px;

  &.btn-large {
    *padding-top: 7px;
    *padding-bottom: 7px;
  }
  &.btn-small {
    *padding-top: 3px;
    *padding-bottom: 3px;
  }
  &.btn-mini {
    *padding-top: 1px;
    *padding-bottom: 1px;
  }
}


// Link buttons
// --------------------------------------------------

// Make a button look and behave like a link
.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}
.btn-link {
  border-color: transparent;
  cursor: pointer;
  border-radius: 0;
}
.btn-link:hover,
.btn-link:focus {
  color: $linkColorHover;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
.btn-link[disabled]:focus {
  color: $grayDark;
  text-decoration: none;
}

.bitty-btn {
  @include media($bitty) {
    @include fill-parent;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 10px;
  }
}

// Google buttons
.red,
.red:active,
.red:visited {
  color: #fff;
  border-color: #b0281a;
  border-bottom-color: #af301f;
  background-color: #c53727;

  background-image: -webkit-gradient(linear,left top,left bottom,from(#dd4b39),to(#c53727));
  background-image: -webkit-linear-gradient(top,#dd4b39,#c53727);
  background-image: -moz-linear-gradient(top,#dd4b39,#c53727);
  background-image: -ms-linear-gradient(top,#dd4b39 0%,#c53727 100%);
  background-image: linear-gradient(to bottom,#dd4b39,#c53727);
  &:hover,
  &:focus {
    color: #fff;
    border-color: #b0281a;
    border-bottom-color: #af301f;
    background-color: #c53727;

    background-image: -webkit-gradient(linear,left top,left bottom,from(#dd4b39),to(#c53727));
    background-image: -webkit-linear-gradient(top,#dd4b39,#c53727);
    background-image: -moz-linear-gradient(top,#dd4b39,#c53727);
    background-image: -ms-linear-gradient(top,#dd4b39 0%,#c53727 100%);
    background-image: linear-gradient(to bottom,#dd4b39,#c53727);
  }
}

.blue,
.blue:active,
.blue:visited {
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  outline: none;
  padding: 0 0;
  width: 60px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #4285f4;
  background: -webkit-linear-gradient(top,#4387fd,#4683ea);
  background: linear-gradient(top,#4387fd,#4683ea);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4387fd,endColorstr=#4683ea,GradientType=1);
}
#wallet-button { text-align: center; }
