.my_account {
  overflow: hidden;
  @include span-columns(12);
  @include omega;
  .right_content {
      label {
        font-weight: bold;
        padding: 8px 0 0 0;
        letter-spacing: .5px;
        cursor: default;
      }
    form {
      .controls {
        margin: 10px 0 10px 180px;
        font-weight: bold;
        @include media($mobile) {
          @include span-columns(12);
          text-align: left;
          margin-left: 0px;
        }
      }

    }
    .required {
      color: $red;
      font-weight: bold;
      font-size: 18px;
    }
    @include span-columns(10);
    @include omega;
    @include pad();
    @include media($mobile) { @include span-columns(12); }
    .list {
      @include span-columns(9 of 10);
      @include shift(1);

      ul {
        @include container-fixed();
        @include pad(40px 0 0 0);

        li {
          list-style-type: none;
          margin-left: 0;
          display: inline;
          float: left;
          width: 160px;
          height: 150px;
          position: relative;
          margin: 0 60px 50px 0;
          &.first {
            clear: left;
          }
          &.last { margin-right: 0; }
        }
      }
    }
  }
}
