// @import "compass/css3";

// Variables
$subMenuItemWidth: 155px;     // Minimum width for submenus' items
$baseMenuBackground: #fff;      // Base color theme
$secondaryMenuBackground: $blue;  // Secondary color (highlights, triangles...)
$baseMenuColor: $grayDarker;       // Proper menu items color
$gutter: .8em;           // Base gutter
$input: "input[type=checkbox]";   // Checkbox hack selector

// Menu background mixin
@mixin menu-background($color, $menu-gradient: true) {
  background-color: $color;
  @if $menu-gradient == true {
    @include background-image(linear-gradient(rgba(255, 255, 255, .2),
      rgba(255, 255, 255, 0)));
  }
  @else {
    background-image: none;
  }
}

// Menu background mixin
// The $size argument specifies the actual size of the triangle
// The $shift-left helps on horizontal positioning.
@mixin pseudo-triangle($size, $shift-left) {
  &:after {
    content: '';
    position: absolute;
    left: $shift-left * $gutter;
    top: -$size * 2;
    border: $size solid transparent;
    border-bottom-color: $grayLighter;
  }
}

// Shortest clearfix. Ever. IE8+.
// http://www.css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
%cf:after {
  content:"";
  display:table;
  clear:both;
}

// Fix for Android
// http://timpietrusky.com/advanced-checkbox-hack
body {
  -webkit-animation: bugfix infinite 1s;
}

@-webkit-keyframes bugfix {
  from { padding: 0; }
  to { padding: 0; }
}

// New box model, small reset, global stuff
.animenu-wrapper {
  @include box-shadow(0 1px 1px 0 rgba(0,0,0,0.25));
  @include media($mobile) {
    height: 45px;
  }
}
.animenu, .desktop-menu {
  font-family: Arial, sans-serif;
  @include span-columns(12);
  @include media($tablet) {
    padding-left: 10px;
  }
  * {
    @include box-sizing(border-box);
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include border-radius(2px);
  }

  li {
    position: relative;
    a.selected {
      font-weight: bold;
      color: $blue;
    }
    a.chat-link,
    .chat-link:hover {
      width: 80px;
      margin-top: 8px;
      padding: 5px 10px;
      color: $white;
      border: none;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: #4285f4;
      background: -webkit-linear-gradient(top,#4387fd,#4683ea);
      background: linear-gradient(top,#4387fd,#4683ea);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4387fd,endColorstr=#4683ea,GradientType=1);
    }
    &:hover {
      > ul {
        opacity: 1;
        visibility: visible;
        margin: 0;
      }
      > a {
        color: lighten($baseMenuColor, 20%);
      }
    }
  }
  #{$input} {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  label {
    display:none;
    cursor: pointer;
  }
}
.desktop-menu {
  @include span-columns(3);
  @include omega;
  margin-top: 14px;
  @include media($tablet) {
    @include span-columns(4);
  }
  ul { float: right; }
  .shopping-cart-count {
    @include border-radius(3px);
    font-size: 12px;
    padding: 5px 10px;
    font-weight: 700;
    color: #fff;
    line-height: 1.5;
    border-color: #b0281a;
    border-bottom-color: #d43f3a;
    background-color: #d9534f;
  }
}
// First level -> main menu items
// <nav class="animenu">
//    <ul></ul>
//  </nav>
.animenu > ul,
.desktop-menu > ul {
  @include box-shadow(0 1px 0 rgba(255, 255, 255, .2) inset);
  @extend %cf;
  > li {
    float: left;
    @include box-shadow(1px 0 0 lighten($baseMenuBackground, 20%));
    > a {
      float: left;
      padding: $gutter 3 * $gutter $gutter 0;
    }
  }
  a {
    color: $baseMenuColor;
    text-decoration: none;
  }

  // Second level
  // <nav class="animenu">
  //    <ul>
  //      <ul></ul>
  //    </ul>
  //  </nav>
  ul {
    position: absolute;
    top: 100%; left: 0;
    opacity: 0;
    visibility: hidden;
    margin: 2 * $gutter 0 0 0;
    z-index: 100;
    @include menu-background(lighten($baseMenuBackground, 15%), false);
    @include transition-property("margin, opacity");
    @include transition-duration(".15s");
    @include transition-timing-function(ease-in-out);
    border: 1px solid $grayLighter;
    box-shadow: 0 1px 2px rgba(0,0,0,0.8);
    li {
      display: block;
      @include box-shadow(0 1px 0 lighten($baseMenuBackground, 5%),
        0 2px 0 lighten($baseMenuBackground, 25%));
      &:first-child > a {
        @include border-radius(3px 3px 0 0);
      }
      &:last-child > a {
        @include border-radius(0 0 3px 3px);
      }
    }
    a {
      padding: $gutter;
      width: $subMenuItemWidth;
      display: block;
      border-color: lighten($baseMenuBackground, 15%);
      &:hover {
        background-color: $secondaryMenuBackground;
        border-color: $secondaryMenuBackground;
        color: white;
        text-decoration: none;
      }
    }
  }
}
.desktop-menu > ul {
  li {
    box-shadow: none;
    > a {
      &:hover,
      &:active { text-decoration: none; }
    }
    &:last-child {
      a:link { padding-right: 0; }
    }
  }
  ul {
    left: -110px;
  }

}

// Last but not least: the responsive stuff
@include media($mobile) {

  %revert-list {
    visibility: visible;
    opacity: 1;
    display: none;
  }

  .animenu {
    #{$input}:checked ~ label,
    #{$input} ~ label:hover, {
      color: lighten($baseMenuColor, 20%);
    }
    label {;
      @include menu-background($baseMenuBackground);
      @include box-shadow(0 1px 0 rgba(255, 255, 255, .2) inset);
      color: $baseMenuColor;
      text-shadow: 0 1px 0 $baseMenuBackground;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      padding: 1.5em 3em;
      &:before {
        position: absolute;
        top: -.1em;
        left: .3em;
        content: "\2261";
        font-size: 3em;
      }
    }
    // First level -> main menu items
    // <nav class="animenu">
    //    <ul></ul>
    //  </nav>
    > ul {
      position: relative;
      border-color: $baseMenuBackground;
      margin: 1.4em 0 !important;
      padding: $gutter / 4;
      @include box-shadow(none);
      @include menu-background($baseMenuBackground, false);
      @extend %revert-list;
      li {
        display: block;
        &:nth-child(odd) {
          @include menu-background(darken($baseMenuBackground, 10%), false);
        }
      }
      > li {
        float: none;
        border: 0;
        @include box-shadow(none);
        @include menu-background($baseMenuBackground, false);

        > a {
          float: none;
          display: block;
          padding: 1.5em;
        }
      }
      // Second level
      // <nav class="animenu">
      //    <ul>
      //      <ul></ul>
      //    </ul>
      //  </nav>
      ul {
        position: static;
        @include border-radius(0);
        @include menu-background(darken($baseMenuBackground, 10%), false);
        margin: 0;
        @extend %revert-list;
        @include transition-property("none");
        li {
          display: none;
        }
        a {
          padding-left: 3 * $gutter;
          display: block;
          width: auto;
        }
      } // end second level
    } // end first level
    #{$input}:checked {
      ~ ul {
        display: block;
        ul {
          display: block;
        }
      }
    } // end #{$input}:checked
  } // end .animenu

} //end @media


// Adjust menu when resizing [in this case].
// To be updated depending of menu items length.
@media screen and (max-width: 600px) {
  .animenu > ul {
    > li {
      > a {
        padding: $gutter 2 * $gutter;
      }
    }
  }
}

.svg-icon {
  margin: 5px 10px;
  vertical-align: middle;
  height: 29px;
  @include media($mobile) {
    display: inline-block !important;
    background-color: #fff !important;
  }
  a {
    padding: 0 !important;
  }
  .new {
    margin-left: 15px;
    width: 29px;
  }
  .eco {
    width: 20px;
    height: 29px;
  }
  .usa {
    width: 29px;
    height: 29px;
  }
  .sale {
    width: 29px;
  }
}
