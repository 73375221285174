.support-nav {
  position: absolute;
  margin-top: 60px;
  right: 175px;
  z-index: $zindexModal;
  width: 300px;
  background-color: $white;
  border: 1px solid;
  border-color: #bbb #bbb #a8a8a8 #bbb;
  border-radius: 2px;
  box-shadow: 0 4px 16px rgba(0,0,0,0.2);
  @include background-clip(padding-box);
  // Remove focus outline from opened modal
  outline: none;
  &.fade {
    @include transition(opacity .3s linear);
    top: 25%;
  }
  &.fade.in { top: 25%; }
  form {
    margin: 0;
  }
  @include media($tablet) {
    top: 2%;
  }
  @include media($mobile) {
    right: 0;
  }
}
.supportNav-header {
  padding: 10px 50px 10px;
  height: 35px;
  h5 { margin: 0; display: inline; }
  background-color: $wellBackground;
  // Close icon
  .close:before {
    content: '\F00D';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #000;
    font-size: 18px;
    padding-right: 0.8em;
    position: absolute;
    top: 10px;
    right: 1px;
  }
  // Heading
  h3 {
    margin: 0;
    line-height: 30px;
  }
}
.supportNav-body {
  position: relative;
  overflow-y: auto;
  max-height: 600px;
  padding: 0 25px 25px 25px;
  background-color: $wellBackground;
  -webkit-overflow-scrolling: touch;
  font-size: 13px;
  text-align: left;
  margin-bottom: 0;
  h5 { display: inline; }
  ul { list-style-type: none; }
  .title {
      color: $grayDark;
      font-style: italic;
      font-weight: 700;
      }
  .account {
    @include span-columns(6);
  }
  .help {
    @include span-columns(6);
    @include omega;
    border-left: 1px solid #ddd;
    padding-left: 20px;
  }
}
