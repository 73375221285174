// Filters solr search
.filter {
  @include form-label;

  label {
    text-transform: uppercase;
    border-bottom: 1px solid $grayLighter;
    margin-bottom: 10px;
    @include media($mobile) {
    padding-left: 10px;
    }
  }
  a:link,
  a:visited {
    color: $blue;
    font-weight: bold;
    @include media($mobile) {
      font-size: 14px;
    }
  }
  .filter-buttons {
    .size {
      text-align: center;
      font-weight: bold;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      padding: 4px 12px 12px;
      border: none;
      width: 50px;
      height: 50px;
      pointer: cursor;
      line-height: 17px;
      &:hover {
        color: $blue;
      }
    }
  }
}


.menu {
  -webkit-font-smoothing: antialiased;
  perspective: 70;
  -webkit-perspective: 70;
  font-size: 21px;
  ul {
    margin: 10px 0 0 0;
    padding: 0;
    float: right;
    text-align: right;
    @include media($mobile-tab) {
      width: 50%;
    }
  }
  ul li {
    display: inline-block;
    list-style: none;
    cursor: pointer;
    padding: 9px 0 0 30px;

    .shopping-cart-count {
      @include border-radius(2px);
      font-size: 11px;
      padding: 3px 5px;
      font-weight: 700;
      color: #fff;
      border-color: #b0281a;
      border-bottom-color: #af301f;
      background-color: #c53727;
      background-image: -webkit-gradient(linear,left top,left bottom,from(#dd4b39),to(#c53727));
      background-image: -webkit-linear-gradient(top,#dd4b39,#c53727);
      background-image: -moz-linear-gradient(top,#dd4b39,#c53727);
      background-image: -ms-linear-gradient(top,#dd4b39 0%,#c53727 100%);
      background-image: linear-gradient(to bottom,#dd4b39,#c53727);
      position: absolute;
      margin-left: -10px;
      margin-top: -14px;
    }

    a:link,
    a:hover,
    a:active,
    a:visited,
    a:focus { color: $grayDarker; }
    // &.search-icon {
    //   display: none;
    //   @include media($mobile) {
    //     display: inline;
    //   }
    // }
  }
}

.menu-wrapper,
.front,
.back {
  @include span-columns(12);
  @include omega;
  height: 54px;
  background-color: $wellBackground;
}
.menu-wrapper {
  overflow: hidden;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  position: relative;
}
// Mobile seach
.flip .front {
  -webkit-transition: all 1.3s;
  transition: all 1.3s;
  transform: scale(0.9, 0.9);
  -webkit-transform: scale(0.9, 0.9);
  transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  /* IE 9 */
  -webkit-transform-origin: 50% 50%;
  /* Safari and Chrome */
  opacity: 0.5;
}
.flip .back {
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  opacity: 1;
  top: 0;
  padding-top: 15px;
}
.front {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.back {
  position: absolute;
  opacity: 0;
  top: -70px;
  left: 0;
}
/* front pane, placed above back */
.front {
  z-index: 2;
}
.back {
  z-index: 3;
}
/* back, initially hidden pane */
.back .close-icon {
  padding: 0 0 0 10px;
  margin: 0;
  float: right;
}
.back .search-btn {
  float: left;
  @include media($bitty) {
    display: none;
  }
}
.back input {
  float: left;
}
.close-icon {
  cursor: pointer;
  font-size: 21px;
}

