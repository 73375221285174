.items-contain {
  text-align: left;
  @include span-columns(9);
  white-space: nowrap;
  @include media($mobile) {
    @include span-columns(12);
    @include omega;
  }
  table {
    margin: 10px 0;
    width: 100%;
  }
  @include media($mobile) {
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      position: relative;
    }
  }
  .headers {
    tr th {
      line-height: 18px;
      text-transform: uppercase;
      border-bottom: 3px solid $grayLighter;
      padding: 0 0 5px 0;
    }
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5) { padding-right: 15px; }
  }
  .items {
    tr {
      border-bottom: 1px solid $grayLighter;
    }
    td {
      line-height: 18px;
      padding: 5px;
      &:first-child { padding-left: 0; }
      &:nth-child(3),
      &:nth-child(4),
      &:last-child { padding: 5px 15px 5px 0 }
      @include media($mobile) {
        padding: 5px 0 !important;
      }
      h5, p {
        white-space: normal;
        margin: 0 0 2px 0;
      }
      input[type="checkbox"] { margin: 0 5px 0 0; }
      .remove {
        font-size: $fontSizeSmall;
        margin-top: 10px;
      }
      &.description {
        max-width: 350px;
        .alert {
          background-color: transparent !important;
          border: 0;
          padding: 0;
          text-shadow: none;
          padding-right: 5px;
        }
      }
    }
    .giftcard-input {
      td:last-child {
        padding-right: 65px;
      }
    }
    .message-lines {
      line-height: 18px;
      max-width: 350px;
      padding-left: 0;
      padding-right: 55px;
      margin-left: -50px;
      @include media($mobile) {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
      input {
        display: block;
        width: 100%;
        margin: 10px 0;
      }
    }
  }
.item-total {
  font-weight: 700;
  position: relative;
  z-index: -1;
}
.slide-contain {
  position: relative;
  .slide-edit {
    position: absolute;
    margin-top: -45px;
    margin-left: -10px;
    background: #fff;
    z-index: 5;
    width: 80px;
    height: 80px;
    display: none;
    padding: 10px 0 0 0;
    @include media($mobile) {
      padding: 0;
      top: 0;
      right: 0;
      width: 100px;
      height: 90px;
      background: rgba(0,0,0,0.9);
    }
  }
}
.btn-update {
  position: absolute;
  @include media($mobile) {
    position: relative;
    margin: 10px 0 20px;
    font-size: 14px;
  }
}
.link-cancel {
  position: absolute;
  text-align: center;
  font-size: 10px;
  margin-left: 15px;
  margin-top: 20px;
  @include media($mobile) {
    position: relative;
    text-align: left;
    font-size: 14px;
    color: $white;
    margin: 0;
  }
}
.gift-slide {
  text-align: center;
  @include media($mobile) {
    padding: 0;
    top: 0;
    right: 0;
    width: 100px;
    height: 90px;
    background: rgba(0,0,0,0.9);
    margin-bottom: 30px;
  }
}
.dropdown { display: inline-block; }
}

.basket-total {
  @include span-columns(3);
  @include omega;
  overflow: hidden;
  @include media($mobile) {
    @include span-columns(12);
    @include omega;
  }
  .gift-card-contain {
    display: block;
    margin: 10px 0 25px auto;
    text-align: center;
    cursor: pointer;
  }
  ul {
    list-style-type: none;
    background-color: lighten($body,5%);
    padding: 10px;
    @extend %container-shadow;
  }
  li.checkout-info {
    .btn {
      display: block;
      margin-bottom: 30px;
      @include media($mobile) {
        font-size: 14px;
      }
    }
    p {
      margin: 5px 0;
      span { float: right; }
    }
  }
  li.promo-code {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    label { padding: 0; }
    input { width: 68%; }
    .btn {
      float: right;
      padding: 6px;
    }
  }
}