//
// Variables
// --------------------------------------------------
@include font-face(OpenSans, '../fonts/OpenSans-Regular-webfont', normal, normal);
@include font-face(OpenSans, '../fonts/OpenSans-Bold-webfont', bold, normal);
@include font-face(OpenSans, '../fonts/OpenSans-Italic-webfont', normal, italic);
@include font-face(OpenSans, '../fonts/OpenSans-Light-webfont', 300, normal);


// Global values
// --------------------------------------------------
$base: #15c;
$firm: #333333;
$body: #e7e7e7;


// Grays
// -------------------------
$black:                 #000 !default;
$grayDarkest:           #656565 !default;
$grayDarker:            #222 !default;
$grayDark:              #333 !default;
$gray:                  #555 !default;
$grayLight:             #999 !default;
$grayLighter:           #f5f5f5 !default;
$white:                 #fff !default;


// Accent colors
// -------------------------
$blue:                  #4b7abe !default;
$blueDark:              #0064cd !default;
$green:                 #46a546 !default;
$red:                   #9d261d !default;
$yellow:                #ffc40d !default;
$orange:                #f89406 !default;
$pink:                  #c3325f !default;
$purple:                #7a43b6 !default;


// Scaffolding
// -------------------------
$bodyBackground:        #ffffff !default;
$textColor:             $grayDark !default;
$footerBackground:      #dadada !default;

// Links
// -------------------------
$linkColor:             $base !default;
$linkColorHover:        darken($linkColor, 15%) !default;


// Typography
// -------------------------
$font-family-sans-serif:  'OpenSans', Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;
$fontAwesome:           FontAwesome !default;

$font-size-base:          13px !default;
$font-size-large:         ceil($font-size-base * 1.25) !default; // ~18px
$font-size-small:         ceil($font-size-base * 0.85) !default; // ~12px

$font-size-h1:            floor($font-size-base * 2.6) !default; // ~36px
$font-size-h2:            floor($font-size-base * 2.15) !default; // ~30px
$font-size-h3:            ceil($font-size-base * 1.7) !default; // ~24px
$font-size-h4:            ceil($font-size-base * 1.25) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil($font-size-base * 0.85) !default; // ~12px

$line-height-base:        1.428571429 !default; // 20/14
$line-height-computed:    $font-size-base * $line-height-base !default; // ~20px

$headings-font-family:    $font-family-base !default;
$headings-font-weight:    400 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;
// Component sizing
// -------------------------
// Based on 14px font-size and 20px line-height

$fontSizeLarge:         $font-size-base * 1.25; // ~18px
$fontSizeSmall:         $font-size-base * 0.85; // ~12px
$fontSizeMini:          $font-size-base * 0.75; // ~11px

$paddingLarge:          11px 19px !default; // 44px
$paddingSmall:          5px 10px !default;  // 26px
$paddingMini:           0px 6px !default;   // 22px

$baseBorderRadius:      2px !default;
$borderRadiusLarge:     6px !default;
$borderRadiusSmall:     2px !default;


// Tables
// -------------------------
$tableBackground:                   transparent !default; // overall background-color
$tableBackgroundAccent:             #f9f9f9 !default; // for striping
$tableBackgroundHover:              #f5f5f5 !default; // for hover
$tableBorder:                       #ddd !default; // table and cell border

// Buttons
// -------------------------
$btnBackground:                     $white !default;
$btnBackgroundHighlight:            darken($white, 10%) !default;
$btnBorder:                         #dcdcdc !default;

$btnPrimaryBackground:              $linkColor !default;
$btnPrimaryBackgroundHighlight:     adjust-hue($btnPrimaryBackground, 20%) !default;

$btnInfoBackground:                 #5bc0de !default;
$btnInfoBackgroundHighlight:        #2f96b4 !default;

$btnSuccessBackground:              #62c462 !default;
$btnSuccessBackgroundHighlight:     #51a351 !default;

$btnWarningBackground:              lighten($orange, 15%) !default;
$btnWarningBackgroundHighlight:     $orange !default;

$btnDangerBackground:               #ee5f5b !default;
$btnDangerBackgroundHighlight:      #bd362f !default;

$btnInverseBackground:              #444 !default;
$btnInverseBackgroundHighlight:     $grayDarker !default;


// Forms
// -------------------------
$inputBackground:               $white !default;
$inputBorder:                   #ccc !default;
$inputBorderRadius:             $baseBorderRadius !default;
$inputDisabledBackground:       $grayLighter !default;
$formActionsBackground:         #f5f5f5 !default;
$inputHeight:                   $line-height-base + 10px; // base line-height + 8px vertical padding + 2px top/bottom border

// Dropdowns
// -------------------------
$dropdownBackground:            $white !default;
$dropdownBorder:                rgba(0,0,0,.2) !default;
$dropdownDividerTop:            #e5e5e5 !default;
$dropdownDividerBottom:         $white !default;

$dropdownLinkColor:             $grayDark !default;
$dropdownLinkColorHover:        $white !default;
$dropdownLinkColorActive:       $white !default;

$dropdownLinkBackgroundActive:  $linkColor !default;
$dropdownLinkBackgroundHover:   $dropdownLinkBackgroundActive !default;



// COMPONENT VARIABLES
// --------------------------------------------------


// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)
$zindexDropdown:          1000 !default;
$zindexPopover:           1010 !default;
$zindexTooltip:           1030 !default;
$zindexFixedNavbar:       1030 !default;
$zindexModalBackdrop:     1040 !default;
$zindexModal:             1050 !default;


// Sprite icons path
// -------------------------
$iconSpritePath:          "../img/glyphicons-halflings.png" !default;
$iconWhiteSpritePath:     "../img/glyphicons-halflings-white.png" !default;


// Input placeholder text color
// -------------------------
$placeholderText:         $grayLight !default;


// Hr border color
// -------------------------
$hrBorder:                $grayLighter !default;


// Horizontal forms & lists
// -------------------------
$horizontalComponentOffset:       180px !default;


// Wells
// -------------------------
$wellBackground:                  #f5f5f5 !default;


// Navbar
// -------------------------
$navbar-height:                    50px !default;
$navbar-margin-bottom:             $line-height-computed !default;
$navbar-border-radius:             3px;
$navbar-padding-horizontal:        20px !default;
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;

$navbar-default-color:             #777 !default;
$navbar-default-bg:                #f8f8f8 !default;
$navbar-default-border:            darken($navbar-default-bg, 6.5%) !default;

// Navbar links
$navbar-default-link-color:                #777 !default;
$navbar-default-link-hover-color:          #333 !default;
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-active-color:         #555 !default;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color !default;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%) !default;
$navbar-default-brand-hover-bg:            transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd !default;
$navbar-default-toggle-icon-bar-bg:        #ccc !default;
$navbar-default-toggle-border-color:       #ddd !default;


// Pagination
// -------------------------
$paginationBackground:                #fff !default;
$paginationBorder:                    #ddd !default;
$paginationActiveBackground:          #f5f5f5 !default;


// Hero unit
// -------------------------
$heroUnitBackground:              $grayLighter !default;
$heroUnitHeadingColor:            inherit !default;
$heroUnitLeadColor:               inherit !default;


// Form states and alerts
// -------------------------
$warningText:             $orange !default;
$warningBackground:       #fcf8e3 !default;
$warningBorder:           darken(adjust-hue($warningBackground, -10), 3%) !default;

$errorText:               #b94a48 !default;
$errorBackground:         #f2dede !default;
$errorBorder:             darken(adjust-hue($errorBackground, -10), 3%) !default;

$successText:             #468847 !default;
$successBackground:       #dff0d8 !default;
$successBorder:           darken(adjust-hue($successBackground, -10), 5%) !default;

$infoText:                #3a87ad !default;
$infoBackground:          #d9edf7 !default;
$infoBorder:              darken(adjust-hue($infoBackground, -10), 7%) !default;


// Tooltips and popovers
// -------------------------
$tooltipColor:            #fff !default;
$tooltipBackground:       #000 !default;
$tooltipArrowWidth:       5px !default;
$tooltipArrowColor:       $tooltipBackground !default;

$popoverBackground:       #fff !default;
$popoverArrowWidth:       10px !default;
$popoverArrowColor:       #fff !default;
$popoverTitleBackground:  darken($popoverBackground, 3%) !default;

// Special enhancement for popovers
$popoverArrowOuterWidth:  $popoverArrowWidth + 1 !default;
$popoverArrowOuterColor:  rgba(0,0,0,.25) !default;